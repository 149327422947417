import React from 'react';

import {
    container,
    labelBox,
    labelText,
    infoText,
    fieldsBox,
    required,
    withPadding,
} from './form-question.module.scss';
import IFormField from '../../models/form-field.model';
import Tooltip from '../atoms/tooltip';
import FieldGenerator from './field-generator';
import useTranslations from '../../hooks/use-translations';

export interface IFormQuestion {
    label: string;
    hint?: string;
    info?: string;
    isRequired?: boolean;
    fields: IFormField[];
}

interface IFormQuestionProps {
    className?: string;
    question: IFormQuestion;
}

const FormQuestion: React.FC<IFormQuestionProps> = ({ className = '', question }) => {
    const t = useTranslations('FormQuestion');
    const { fields, isRequired, label, hint } = question;

    const info = getQuestionInfo(question, t);
    const isWithPadding =
        question.fields[0] && ['radio', 'checkbox'].includes(question.fields[0].type);

    return (
        <div
            className={`${container} ${className} ${isRequired ? required : ''} ${
                isWithPadding ? withPadding : ''
            }`}
        >
            <div className={labelBox}>
                {label && <p className={labelText}>{label}</p>}
                {hint && <Tooltip>{hint}</Tooltip>}
                {info && <p className={infoText}>{info}</p>}
            </div>
            <div className={`${fieldsBox} form-question__fields`}>
                {fields.map((field) => {
                    return <FieldGenerator key={`question-field-${field.name}`} field={field} />;
                })}
            </div>
        </div>
    );
};

function getQuestionInfo(
    question: IFormQuestion,
    t: ReturnType<typeof useTranslations<'FormQuestion'>>
) {
    const { fields } = question;
    const isMulti = fields[0] && fields[0].type === 'checkbox';
    if (isMulti) {
        return t.info.multi;
    }
    return question.info;
}

export default FormQuestion;
