import React from 'react';

import {
    container,
    grid,
    sectionBox,
    actions,
    radiusBox,
    loader,
    loading,
} from './candidate-survey-update.module.scss';
import { ISection } from '../../models/section.model';
import { ISurveyAnswersMutation } from '../../models/survey.model';
import {IPage} from "../../models/page.model";
import {graphql, navigate, useStaticQuery } from 'gatsby';
import {IQueryAllResult} from "../../models/query-all-result.model";
import useTranslations from '../../hooks/use-translations';
import { useSurvey } from '../../hooks/use-survey';
import useRtkQueryFormError from '../../hooks/use-rtk-query-form-error';
import { useModal } from '../../hooks/use-modal';

import Section from '../hoc/section';
import SurveyForm, { ISurveyFormProps } from '../molecules/survey-form';
import RadiusBox from '../hoc/radius-box';
import Button from '../atoms/button';
import Loader from '../atoms/loader';
import { useCandidate } from '../../hooks/use-candidate';
import {usePagePathname} from "../../hooks/use-page-pathname";

export interface ICandidateSurveyUpdateProps {
    className?: string;
    section: ISection;
    TitleTag?: React.ElementType;
}

interface ICandidateSurveyUpdateQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'pathname' | 'type' | 'locale'>>;
}

const CandidateSurveyUpdate: React.FC<ICandidateSurveyUpdateProps> = ({
    className = '',
    section,
    TitleTag = 'h2',
}) => {
    const { style, css, sectionId } = section;
    const t = useTranslations('CandidateSurveyUpdate');
    const survey = useSurvey({ queries: ['questions'] });
    const { formikRef, globalErrorMessage } = useRtkQueryFormError(survey.send.errors);
    const { addModal } = useModal();
    const candidate = useCandidate({ dontSkip: true });
    const { allPage } = useStaticQuery<ICandidateSurveyUpdateQueryResult>(query);
    const candidateDashboardPage = usePagePathname(allPage, 'candidate-dashboard');

    const handleSubmit: ISurveyFormProps['onSubmit'] = async (values) => {
        const newValues = { ...values };
        if ('cv' in newValues) {
            delete newValues.cv;
        }
        const mutation: ISurveyAnswersMutation = {
            ...newValues,
            cv: values.cv,
        };
        try {
            await survey.send.fetch(mutation).unwrap();
            candidate.get.refetch().unwrap();
            addModal({
                modalKey: 'common-modal',
                modalProps: {
                    title: t.success.title,
                    confirmText: t.success.confirm,
                    children: t.success.content,
                    actions: ['confirm'],
                },
            });
        } catch {}
    };

    return (
        <Section
            className={`${sectionBox} ${className} ${survey.send.isLoading ? loading : ''}`}
            classes={{ container: grid }}
            style={style}
            sectionId={sectionId}
            css={css}
        >
            <div className={container}>
                <RadiusBox className={radiusBox}>
                    {survey.questionsQuery.isLoading && <Loader className={loader} />}
                    {survey.questionsQuery.isSuccess && (
                        <SurveyForm
                            questions={survey.questions}
                            onSubmit={handleSubmit}
                            formikRef={formikRef}
                            globalErrorMessage={globalErrorMessage}
                            title={t.title}
                            TitleTag={TitleTag}
                            includeCVField={true}
                        />
                    )}
                </RadiusBox>
                <div className={actions}>
                    <Button onClick={() => {formikRef.current?.resetForm(); navigate(candidateDashboardPage);}} stylePreset="secondary">{t.cancel}</Button>
                    <Button
                        onClick={() => {formikRef.current?.submitForm()}}
                        isLoading={survey.send.isLoading}
                    >
                        {t.submit}
                    </Button>
                </div>
            </div>
        </Section>
    );
};

const query = graphql`
    query {
        allPage(
            filter: {
                type: {
                    in: [
                        "candidate-dashboard"
                    ]
                }
            }
        ) {
            edges {
                node {
                    locale
                    pathname
                    type
                }
            }
        }
    }
`;

export default CandidateSurveyUpdate;
