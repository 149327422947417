import * as Yup from 'yup';
import { AnySchema } from 'yup';
import { getMediaByRelation } from '@alterpage/gatsby-plugin-image';
import { IMedia } from '@alterpage/gatsby-source-alterpress';

import { ISurveyAnswersMutation, ISurveyQuestion } from '../models/survey.model';
import { ILocation } from '../models/location.model';
import { IFormQuestion } from '../components/molecules/form-question';
import { relations } from '../config/relations';
import useTranslations from '../hooks/use-translations';

export function getSurveyFormQuestions(questions: ISurveyQuestion[]): IFormQuestion[] {
    return questions.map((question, index) => {
        return {
            label: `${index + 1}. ${question.label}`,
            isRequired: question.isRequired,
            hint: question.hint,
            fields: [
                {
                    name: `answers.${question.questionId}`,
                    type: question.type,
                    options: question.options,
                    rangeOptions: question.rangeOptions,
                },
            ],
        };
    });
}

export function getSurveyInitialValues(
    questions: ISurveyQuestion[],
    includeCVField?: boolean,
    media?: IMedia[]
): ISurveyAnswersMutation {
    const initialValues: Record<string, string | string[] | ILocation[]> = {};
    questions.forEach((question) => {
        let initialValue: string | string[] | ILocation[] = '';
        if (question.answer) {
            initialValue = question.answer;
        } else {
            if (question.type === 'checkbox') {
                initialValue = [];
            }
            if (question.type === 'location') {
                initialValue = [
                    {
                        name: '',
                        lat: '',
                        lon: '',
                        range: '',
                    },
                ];
            }
        }
        initialValues[question.questionId.toString()] = initialValue;
    });
    const cvMedia = getMediaByRelation({
        media,
        relation: relations.attachment,
        useFallback: false,
    });
    return {
        answers: {
            ...initialValues,
        },
        ...(includeCVField
            ? {
                  cv: cvMedia
                      ? {
                            name: cvMedia.name,
                            url: cvMedia.url,
                            mimeType: cvMedia.type,
                            fileId: cvMedia.fileId,
                            content: null,
                        }
                      : undefined,
              }
            : {}),
    };
}

export function getSurveyValidationSchema(
    questions: ISurveyQuestion[],
    t: ReturnType<typeof useTranslations<'SurveyForm'>>
) {
    const schema = questions.reduce<Record<string, AnySchema>>((acc, question) => {
        if (!question.isRequired) return acc;
        let schema: AnySchema = Yup.string().required(t.error.required);
        if (question.type === 'checkbox') {
            schema = Yup.array().min(1, t.error.required).required(t.error.required);
        }
        if (question.type === 'location') {
            schema = Yup.array()
                .of(
                    Yup.object({
                        name: Yup.string().required(t.error.required),
                        range: Yup.string().required(t.error.required),
                    })
                )
                .min(1, t.error.required)
                .required(t.error.required);
        }
        acc[question.questionId.toString()] = schema;
        return acc;
    }, {});
    return Yup.object().shape({
        answers: Yup.object(schema),
    });
}
