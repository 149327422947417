import React, { useEffect, useState } from 'react';
import { Form, Formik, FormikConfig, FormikProps } from 'formik';

import {
    container,
    errorText,
    fieldList,
    fileBox,
    fileBoxDisclaimer,
    fileBoxIcon,
    fileBoxTitle,
    fileInput,
    first,
    second,
    separator,
    titleText,
    uploadInfo,
} from './survey-form.module.scss';
import { ISurveyQuestion } from '../../models/survey.model';
import {
    getSurveyFormQuestions,
    getSurveyInitialValues,
    getSurveyValidationSchema,
} from '../../formik/survey.form';
import useTranslations from '../../hooks/use-translations';
import { useCandidate } from '../../hooks/use-candidate';

import IconFactory from '../hoc/icon-factory';
import Markdown from '../hoc/markdown';
import InputFile from '../atoms/input-file';
import FormQuestion from './form-question';
import { useSharedFile } from '../../hooks/use-shared-file';
import { ESharedFileRelation } from '../../models/shared-file.model';

export interface ISurveyFormProps {
    className?: string;
    TitleTag?: React.ElementType;
    title?: string;
    formikRef?: React.Ref<FormikProps<any>>;
    globalErrorMessage?: string;
    questions: ISurveyQuestion[];
    onSubmit: FormikConfig<any>['onSubmit'];
    includeCVField?: boolean;
}

const SurveyForm: React.FC<ISurveyFormProps> = ({
    className = '',
    TitleTag = 'h2',
    title,
    formikRef,
    questions = [],
    onSubmit,
    includeCVField = false,
    globalErrorMessage,
}) => {
    const t = useTranslations('SurveyForm');
    const candidate = useCandidate();
    const privacyPolicyFile = useSharedFile(ESharedFileRelation.privacyPolicy);
    const [initialValues, setInitialValues] = useState(
        getSurveyInitialValues(questions, includeCVField)
    );
    const [validationSchema, setValidationSchema] = useState(
        getSurveyValidationSchema(questions, t)
    );

    const formQuestions = getSurveyFormQuestions(questions);

    useEffect(() => {
        setInitialValues(getSurveyInitialValues(questions, includeCVField, candidate.data?.media));
        setValidationSchema(getSurveyValidationSchema(questions, t));
    }, [candidate.data?.media, includeCVField, questions, t]);

    return (
        <Formik
            innerRef={formikRef}
            onSubmit={onSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
        >
            {({ errors, submitCount }) => (
                <Form className={`${container} ${className}`}>
                    {title && (
                        <>
                            <TitleTag className={titleText}>{title}</TitleTag>
                            <span className={`${separator} ${first}`} />
                        </>
                    )}
                    <div className={fieldList}>
                        {formQuestions.map((formQuestion, index) => {
                            return (
                                <FormQuestion
                                    key={`survey-question-${index}`}
                                    question={formQuestion}
                                />
                            );
                        })}
                    </div>
                    {includeCVField && (
                        <>
                            <span className={`${separator} ${second}`} />
                            <div className={fileBox}>
                                <p className={fileBoxTitle}>{t.file.title}</p>
                                <div className={fileBoxDisclaimer}>
                                    <IconFactory className={fileBoxIcon} icon="info" />
                                    <Markdown>{t.file.disclaimer}</Markdown>
                                </div>
                                <InputFile
                                    className={fileInput}
                                    name="cv"
                                    dropboxText={t.file.dropbox}
                                />
                            </div>
                            <Markdown className={uploadInfo}>
                                {t.uploadInfo(privacyPolicyFile?.pathname || '')}
                            </Markdown>
                        </>
                    )}
                    {globalErrorMessage && <p className={errorText}>{globalErrorMessage}</p>}
                    {!globalErrorMessage && Object.keys(errors).length > 0 && submitCount > 0 && (
                        <p className={errorText}>{t.errorWarning}</p>
                    )}
                </Form>
            )}
        </Formik>
    );
};

export default SurveyForm;
