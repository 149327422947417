import { useMemo } from 'react';

import { useSelector } from '../redux/hooks';
import {
    useGetSurveyAnswersQuery,
    useSendSurveyAnswersMutation,
    useGetSurveyQuestionsQuery,
    useGetClientQuestionsMutation,
} from '../redux/api/survey';
import { getRtkQueryErrors } from '../utils/get-rtk-query-errors';
import useTranslations from './use-translations';
import { selectSurveyState } from '../redux/slices/survey.slice';
import { useCandidate } from './use-candidate';

export type TUseSurveyQueries = 'questions' | 'answers';

interface IUseSurveyConfig {
    queries?: TUseSurveyQueries[];
    clientId?: number | string | null;
    withoutAnswered?: boolean;
}

export const useSurvey = ({
    queries = [],
    withoutAnswered,
    clientId,
}: IUseSurveyConfig | undefined = {}) => {
    const t = useTranslations('RTKQueryApi');
    const surveyState = useSelector(selectSurveyState);
    const candidate = useCandidate();

    const skipQuestionsQuery = !queries.includes('questions') || !candidate.data;
    const skipAnswersQuery = !queries.includes('answers') || !candidate.data;

    const getQuestionsQuery = useGetSurveyQuestionsQuery(
        { clientId, withoutAnswered },
        { skip: skipQuestionsQuery }
    );
    const getAnswersQuery = useGetSurveyAnswersQuery(undefined, { skip: skipAnswersQuery });

    const [sendAnswers, sendAnswersMutation] = useSendSurveyAnswersMutation();
    const [getClientQuestions, getClientQuestionsMutation] = useGetClientQuestionsMutation();

    const questionsErrors = useMemo(() => {
        return getRtkQueryErrors(getQuestionsQuery.error, t.errors);
    }, [getQuestionsQuery.error, t.errors]);
    const answersErrors = useMemo(() => {
        return getRtkQueryErrors(getAnswersQuery.error, t.errors);
    }, [getAnswersQuery.error, t.errors]);
    const sendAnswersErrors = useMemo(() => {
        return getRtkQueryErrors(sendAnswersMutation.error, t.errors);
    }, [sendAnswersMutation.error, t.errors]);
    const getClientQuestionsErrors = useMemo(() => {
        return getRtkQueryErrors(getClientQuestionsMutation.error, t.errors);
    }, [getClientQuestionsMutation.error, t.errors]);

    return {
        ...surveyState,
        questionsQuery: {
            data: getQuestionsQuery.data,
            isFetching: getQuestionsQuery.isFetching,
            isLoading: getQuestionsQuery.isLoading,
            isSuccess: getQuestionsQuery.isSuccess,
            isError: getQuestionsQuery.isError,
            errors: questionsErrors,
        },
        answersQuery: {
            data: getAnswersQuery.data,
            isFetching: getAnswersQuery.isFetching,
            isLoading: getAnswersQuery.isLoading,
            isSuccess: getAnswersQuery.isSuccess,
            isError: getAnswersQuery.isError,
            errors: answersErrors,
        },
        send: {
            fetch: sendAnswers,
            data: sendAnswersMutation.data,
            isLoading: sendAnswersMutation.isLoading,
            isSuccess: sendAnswersMutation.isSuccess,
            isError: sendAnswersMutation.isError,
            errors: sendAnswersErrors,
        },
        clientQuestions: {
            fetch: getClientQuestions,
            data: getClientQuestionsMutation.data,
            isLoading: getClientQuestionsMutation.isLoading,
            isSuccess: getClientQuestionsMutation.isSuccess,
            isError: getClientQuestionsMutation.isError,
            errors: getClientQuestionsErrors,
        },
    };
};
