// extracted by mini-css-extract-plugin
export var backgroundAnimation = "survey-form-module--backgroundAnimation--75966";
export var container = "survey-form-module--container--3b839";
export var errorText = "survey-form-module--error-text--89bf4";
export var fieldList = "survey-form-module--field-list--f0aee";
export var fileBox = "survey-form-module--file-box--786a2";
export var fileBoxDisclaimer = "survey-form-module--file-box-disclaimer--a6b39";
export var fileBoxIcon = "survey-form-module--file-box-icon--16af6";
export var fileBoxTitle = "survey-form-module--file-box-title--fb11e";
export var fileInput = "survey-form-module--file-input--2e9bb";
export var first = "survey-form-module--first--f70a4";
export var second = "survey-form-module--second--30548";
export var separator = "survey-form-module--separator--066ce";
export var titleText = "survey-form-module--title-text--3c47a";
export var uploadInfo = "survey-form-module--upload-info--cb020";